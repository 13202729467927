document.addEventListener("DOMContentLoaded", () => {
  const track = document.querySelector(".c-image-slider__track");
  const dots = document.querySelectorAll(".c-image-slider__dot");
  const totalSlides = dots.length;

  let currentSlide = 0;

  // Function to move the slider
  const moveToSlide = (index) => {
    const slideWidth = document.querySelector(
      ".c-image-slider__image"
    ).clientWidth;
    const gap = 20; // Total margin (left + right)
    const offset = (track.parentElement.clientWidth - slideWidth - gap) / 2; // Center offset
    track.style.transform = `translateX(calc(-${
      index * (slideWidth + gap)
    }px + ${offset}px))`;
    dots.forEach((dot) => dot.classList.remove("active"));
    dots[index].classList.add("active");
  };

  // Auto-slide functionality
  const autoSlide = () => {
    currentSlide = (currentSlide + 1) % totalSlides;
    moveToSlide(currentSlide);
  };

  let slideInterval = setInterval(autoSlide, 3000);

  // Dots navigation
  dots.forEach((dot, index) => {
    dot.addEventListener("click", () => {
      clearInterval(slideInterval); // Pause auto-slide on manual interaction
      moveToSlide(index);
      currentSlide = index;
      slideInterval = setInterval(autoSlide, 3000); // Restart auto-slide
    });
  });

  // Initialize first slide as active
  moveToSlide(0);
});
